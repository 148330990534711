<template>
  <div
    v-loading="loading"
    class="container"
  >
    <el-form
      ref="form"
      :model="information"
      label-position="top"
    >
      <el-form-item
        v-for="item in config"
        v-show="!item.private"
        :key="item.filed_name"
        :prop="item.filed_name"
        :rules="item.validation.required?[{
          required: item.validation.required, message: $t('required_notification'), trigger: 'change'
        }]:null"
      >
        <span
          slot="label"
          class="label"
        >
          {{ item.label }}
        </span>
        <div
          v-if="item.notes"
          class="note"
          v-html="item.notes"
        />
        <div v-if="item.type === 'single_line_text'">
          <el-input
            v-model="information[item.filed_name]"
            :disabled="item.filed_name === asset_code_filed"
          />
        </div>
        <div
          v-if="item.type==='email'"
        >
          <el-input
            v-model="information[item.filed_name]"
            prefix-icon="fas el-icon-fa-envelope"
            type="email"
          />
        </div>
        <div v-if="item.type==='phone'">
          <el-input
            v-model="information[item.filed_name]"
            prefix-icon="fas el-icon-fa-phone-alt"
            type="email"
          />
        </div>
        <div v-if="item.type==='multiple_choice'">
          <el-checkbox-group
            v-model="information[item.filed_name]"
          >
            <div
              v-for="choice in item.choices"
              :key="choice.value"
            >
              <el-checkbox
                :label="choice.value"
              >
                {{ choice.name }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
        <div v-if="item.type==='single_choice'">
          <el-radio-group
            v-model="information[item.filed_name]"
          >
            <div
              v-for="choice in item.choices"
              :key="choice.value"
              style="line-height: 32px;"
            >
              <el-radio
                :label="choice.value"
              >
                {{ choice.name }}
              </el-radio>
            </div>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="text-center">
          You only need to fill this contact sheet once, it will be auto filled next time.
        </div>
        <div class="text-center">
          After submitting, you will be directed to Vimeo to watch the presentation.
        </div>
      </el-form-item>
      <el-form-item>
        <div class="text-center">
          <el-button
            type="primary"
            @click="confirm"
          >
            {{ $t('confirm') }}
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import * as ContactAPI from '@/api/contact'
import { getAssetDetailByCode } from '@/api/assets'

const PRIVATE_FIELD = [
  'field_7',
  'field_5',
  'field_21',
  'field_24',
  'field_22',
  'field_1']
export default @Component({})
class ContactPage extends Vue {
  @Prop({ default: '' }) code
  config = []
  loading = false
  asset = null
  asset_code_filed = null
  information = {}

  mounted () {
    this.getContactConfig()
  }

  async getContactConfig () {
    try {
      this.loading = true
      const data = await ContactAPI.getConfig()
      const { asset } = await getAssetDetailByCode(this.code)
      this.asset = asset
      const cache = localStorage.getItem('biotochina-asset-concat-cache')
      if (cache) {
        this.information = JSON.parse(cache)
      }
      this.config = data.fields.map(item => {
        const temp = {
          filed_name: Object.keys(item)[0],
          ...Object.values(item)[0]
        }
        if (temp.label === 'Asset Code 资产编号') {
          this.asset_code_filed = temp.filed_name
          this.information[temp.filed_name] = this.code
        }
        if (temp.label === '来源 From') {
          const from = this.$route.query.from
          this.information[temp.filed_name] = from || 'demand'
        }
        if (temp.type === 'multiple_choice') {
          this.$set(this.information, temp.filed_name, this.information[temp.filed_name] || [])
        }
        if (PRIVATE_FIELD.includes(temp.filed_name)) {
          temp.private = true
          temp.validation = {
            ...temp.validation,
            required: false
          }
        }
        return temp
      })
    } catch (e) {
      this.$notify.error({
        title: 'Error',
        message: this.$t('loading_fail')
      })
    } finally {
      this.loading = false
    }
  }

  async confirm () {
    const validate = await this.$refs.form.validate()
    if (validate) {
      await ContactAPI.create(this.information)
      localStorage.setItem('biotochina-asset-concat-cache', JSON.stringify(this.information))
      this.$notify.success({
        title: 'Success',
        message: this.$t('confirm_contact_success')
      })

      window.location.href = this.asset?.video || 'https://biotochina.org/2022/10/11/4165/'
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  color: #222222;
  font-size: 15px;
  font-weight: bold;
}

.note {
  :deep(p) {
    color: #666;
    margin: 0;
    font-size: 13px;
  }

  margin-bottom: 10px;
}
</style>
